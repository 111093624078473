<template>
  <div>
    <div v-loading="leftFullscreenLoading" class="recommendGoodsMain">
      <loginDialog ref="dialog"/>
      <nav>
        <div class="typeContainer">
          <!--              <div v-for="(item,index) in typeList" :key="index" :class="activeIndex==index?'activeText':'text'"-->
          <!--                   @click="updateActiveIndex(index)">-->
          <!--                <div>{{ item }}</div>-->
          <!--                <img v-if="activeIndex==index" alt="" src="../../assets/1688/hot.gif">-->
          <!--              </div>-->
          <div class="activeText">
            <div>1688日本市場人気商品</div>
            <img alt="" src="../../../../assets/1688/hot.gif">
          </div>
          <div class="flexAndCenter" style="margin-left: 10px">
            <div class="childsItemContainer">
              <el-select v-model="leftValue" style="width: 200px;" @change="leftOptionChange">
                <el-option
                    v-for="item in leftOption"
                    :key="item.id"
                    :label="item.translate_name"
                    :value="item.chinese_name">
                </el-option>
              </el-select>
            </div>
            <div class="childsItemContainer" style="margin-left: 20px">
              <el-select v-model="leftFrom.keywords" style="width: 200px;" @change="getLeftHotSaleSearch">
                <el-option
                    v-for="item in leftChildrenOption"
                    :key="item.id"
                    :label="item.translate_name"
                    :value="item.chinese_name">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="iconBox">
          <div @click="shopCarouselChange('left')">
            <img alt="" src="../../../../assets/1688/flecha-izquierda.png" style="margin-left: 9px"/>
          </div>
          <div @click="shopCarouselChange('right')">
            <img alt="" src="../../../../assets/1688/flecha-izquierda-1.png" style="margin-left: 12px"/>
          </div>
        </div>
      </nav>
      <div v-if="goodsList.length == 0">
        <el-carousel ref="shopCarousel" :autoplay="autoplay" :interval="3000" height="235px" trigger="click"
                     @mouseover.native="autoplay = false" @mouseleave.native="autoplay = true">
          <el-carousel-item>
            <div class="recommendGoodsBox">
              <div v-for="i in 6" :key="i" class="recommendGoods">
                <div class="imageBox">
                  <div class="notHaveData">
                    <i class="el-icon-loading"></i>
                  </div>
                </div>
                <div style="margin-top: 20px">
                  <el-skeleton animated></el-skeleton>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div v-else>
        <el-carousel ref="shopCarousel" :autoplay="autoplay" :interval="15000" arrow="never" height="235px"
                     indicator-position="none" trigger="click">
          <el-carousel-item v-for="(item, index) in goodsList" v-show="item.status" :key="index">
            <div class="recommendGoodsBox">
              <div v-for="(goodsItem,goodsIndex) in item" :key="goodsIndex"
                   :data-aplus-report="goodsItem.traceInfo"
                   class="recommendGoods" data-tracker="offer"
                   @click="$fun.toCommodityDetails(goodsItem.goodsId, goodsItem.shopType)"
                   @mouseenter="goodsMouseenter(index,goodsIndex)"
                   @mouseleave="goodsMouseleave(index,goodsIndex)">
                <div class="imageBox">
                  <el-image :src="goodsItem.imgUrl">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                    <div slot="placeholder">
                      <i class="el-icon-loading"></i>
                    </div>
                  </el-image>
                  <div v-show="goodsItem.hoverStatus === true" class="findSimilarityContainer"
                       @click.stop="imgSearchGoods(goodsItem.imgUrl)">{{ $fanyi("查找相似") }}
                  </div>
                </div>
                <div style="padding: 0 12px">
                  <div :title="goodsItem.titleT" class="goodsTitle">
                    <span v-if="goodsItem.shopType==1688" class="type">1688</span>
                    {{ goodsItem.titleT }}
                  </div>
                  <div class="priceContainer">
                    <span class="priceBox">
                      {{ goodsItem.goodsPrice }}{{
                        $fanyi("元")
                      }}（{{ (goodsItem.goodsPrice * exchangeRate).toFixed(0) }}{{ $fanyi("円") }}）
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <img class="cursorPointer"
         src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/dest/202409/180158107/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83-%E6%B5%B7%E6%BA%90.jpg"
         style="margin-bottom:12px" @click="bannerPageJump">
    <div class="main">
      <div class="indexNavigationContainer" style="margin-bottom:26px">
        <div class="navigationMenuContainer">{{ $fanyi("订单") }}</div>
        <div class="navigationMenuChildrenContainer">
          <div class="flex" style="margin-bottom: 50px">
            <div class="navigationContainer flexAndCenter margin-right-87"
                 @click="$fun.routerToPage('/user/order?status=temporary')">
              <img src="../../../../assets/personalCenter/index/temporaryOrder.png">
              <div class="numContainer">
                <div>{{ ordersSumList[1].count }}</div>
                <div>{{ $fanyi("临时保存的订单") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter margin-right-56"
                 @click="$fun.routerToPage('/user/order?status=all')">
              <img src="../../../../assets/personalCenter/index/allOrder.png" style="width:45px">
              <div class="numContainer">
                <div>{{ ordersSumList[0].count }}</div>
                <div>{{ $fanyi("所有订单") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter margin-right-31"
                 @click="$fun.routerToPage('/user/order?status=offer')">
              <img alt="" src="../../../../assets/personalCenter/index/offerOrder.png">
              <div class="numContainer">
                <div>{{ ordersSumList[2].count }}</div>
                <div>{{ $fanyi("报价中订单") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter" @click="$fun.routerToPage('/user/order?status=obligation')">
              <img alt="" src="../../../../assets/personalCenter/index/obligationOrder.png">
              <div class="numContainer">
                <div>{{ ordersSumList[3].count }}</div>
                <div>{{ $fanyi("订单待付款") }}</div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="navigationContainer flexAndCenter margin-right-87"
                 @click="$fun.routerToPage('/user/order?status=purchase')">
              <img alt="" src="../../../../assets/personalCenter/index/beBuying.png">
              <div class="numContainer">
                <div>{{ ordersSumList[4].count }}</div>
                <div>{{ $fanyi("正在购买") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter margin-right-56"
                 @click="$fun.routerToPage('/user/order?status=purchased')">
              <img alt="" src="../../../../assets/personalCenter/index/completionOfPurchase.png">
              <div class="numContainer">
                <div>{{ ordersSumList[5].count }}</div>
                <div>{{ $fanyi("购买完成") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter margin-right-31"
                 @click="$fun.routerToPage('/user/order?status=shipped')">
              <img alt="" src="../../../../assets/personalCenter/index/completionOfShipment.png">
              <div class="numContainer">
                <div>{{ ordersSumList[6].count }}</div>
                <div>{{ $fanyi("出货完成") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter" @click="$fun.routerToPage('/user/issueOrder')">
              <img alt="" src="../../../../assets/personalCenter/index/defectiveProduct.png">
              <div class="numContainer">
                <div>{{ ordersSumList[7].count }}</div>
                <div>{{ $fanyi("问题产品") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="indexNavigationContainer" style="margin-bottom:26px">
        <div class="navigationMenuContainer">{{ $fanyi("中国国内物流跟进") }}</div>
        <div class="navigationMenuChildrenContainer">
          <div class="flex">
            <div class="navigationContainer flexAndCenter margin-right-87"
                 @click="$fun.routerToPage('/user/wuliuStatus?status=purchase')">
              <img src="../../../../assets/personalCenter/index/waitingToBuy.png">
              <div class="numContainer">
                <div>{{ chinaLogisticsListTableNum[0].count }}</div>
                <div>{{ $fanyi("等待购买") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter margin-right-56"
                 @click="$fun.routerToPage('/user/wuliuStatus?status=purchased')">
              <img src="../../../../assets/personalCenter/index/itIsBeingDeliveredInChina.png" style="height:45px">
              <div class="numContainer">
                <div>{{ chinaLogisticsListTableNum[1].count }}</div>
                <div>{{ $fanyi("派送中") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter margin-right-31"
                 @click="$fun.routerToPage('/user/wuliuStatus?status=shipped')">
              <img alt="" src="../../../../assets/personalCenter/index/haveBeenSigned.png" style="height:46px">
              <div class="numContainer">
                <div>{{ chinaLogisticsListTableNum[2].count }}</div>
                <div>{{ $fanyi("已到达rakumart仓库") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter"
                 @click="$fun.routerToPage('/user/wuliuStatus?status=putaway')">
              <img alt="" src="../../../../assets/personalCenter/index/onTheShelf.png" style="width:45px">
              <div class="numContainer">
                <div>{{ chinaLogisticsListTableNum[3].count }}</div>
                <div>{{ $fanyi("商品作业中") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="indexNavigationContainer" style="margin-bottom:26px">
        <div class="navigationMenuContainer">{{ $fanyi("仓库") }}</div>
        <div class="navigationMenuChildrenContainer">
          <div class="flex">
            <div class="navigationContainer flexAndCenter margin-right-87"
                 @click="$fun.routerToPage('/warehouse?status=auto')">
              <img src="../../../../assets/personalCenter/index/freeWarehouse.png">
              <div class="numContainer">
                <div>{{ warehouseSumList[0].count }}</div>
                <div>{{ $fanyi("普通仓库") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter" @click="$fun.routerToPage('/warehouse?status=fee')">
              <img src="../../../../assets/personalCenter/index/tollWarehouse.png">
              <div class="numContainer">
                <div>{{ warehouseSumList[1].count }}</div>
                <div>{{ $fanyi("收费仓库") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="indexNavigationContainer">
        <div class="navigationMenuContainer">{{ $fanyi("配送单") }}</div>
        <div class="navigationMenuChildrenContainer">
          <div class="flex" style="margin-bottom: 50px">
            <div class="navigationContainer flexAndCenter margin-right-87"
                 @click="$fun.routerToPage('/user/deliveryList?status=temporary')">
              <img src="../../../../assets/personalCenter/index/temporaryPorder.png">
              <div class="numContainer">
                <div>{{ porderSumList[0].count }}</div>
                <div>{{ $fanyi("临时保存的配送单") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter margin-right-56"
                 @click="$fun.routerToPage('/user/deliveryList?status=all')">
              <img src="../../../../assets/personalCenter/index/allPorder.png" style="width:45px">
              <div class="numContainer">
                <div>{{ porderSumList[5].count }}</div>
                <div>{{ $fanyi("所有配送单") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter margin-right-31"
                 @click="$fun.routerToPage('/user/deliveryList?status=offer')">
              <img src="../../../../assets/personalCenter/index/InPacking.png" style="width:45px;height: 42px">
              <div class="numContainer">
                <div>{{ porderSumList[1].count }}</div>
                <div>{{ $fanyi("货物装箱中") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter"
                 @click="$fun.routerToPage('/user/deliveryList?status=obligation')">
              <img alt="" src="../../../../assets/personalCenter/index/internationalFreightToBePaid.png">
              <div class="numContainer">
                <div>{{ porderSumList[2].count }}</div>
                <div>{{ $fanyi("国际运费待付款") }}</div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="navigationContainer flexAndCenter margin-right-87"
                 @click="$fun.routerToPage('/user/deliveryList?status=purchase')">
              <img alt="" src="../../../../assets/personalCenter/index/internationalLogisticsToBeShipped.png">
              <div class="numContainer">
                <div>{{ porderSumList[3].count }}</div>
                <div>{{ $fanyi("国际物流待发货") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter margin-right-56"
                 @click="$fun.routerToPage('/user/deliveryList?status=shipped')">
              <img src="../../../../assets/personalCenter/index/enRouteToJapan.png"
                   style="width:49px;">
              <div class="numContainer">
                <div>{{ porderSumList[4].count }}</div>
                <div>{{ $fanyi("前往日本途中") }}</div>
              </div>
            </div>
            <div class="navigationContainer flexAndCenter margin-right-31"
                 @click="$fun.routerToPage('/user/deliveryList?status=received')">
              <img alt="" src="../../../../assets/personalCenter/index/haveReceivedTheGoods.png">
              <div class="numContainer">
                <div>{{ porderSumList[6].count }}</div>
                <div>{{ $fanyi("已经收到货物") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginDialog from "@/components/public/loginDialog.vue";

export default {
  components: {loginDialog},
  data() {
    return {
      shopLoading: true,
      goodsList: [],
      autoplay: false,
      leftFrom: {
        keywords: '',
        type: 'left',
        page: 1,
        pageSize: 6
      },
      leftOption: [],
      leftFullscreenLoading: false,
      leftChildrenOption: [],
      leftValue: '',
      leftTotal: 0,
      ordersSumList: [
        {name: "全部", count: 0},
        {name: "临时保存待提出", count: 0},
        {name: "报价中", count: 0},
        {name: "等待付款", count: 0},
        {name: "正在购买", count: 0},
        {name: "采购完了", count: 0},
        {name: "出货完成", count: 0},
        {name: "问题产品", count: 0}
      ],
      chinaLogisticsListTableNum: [
        {name: "等待购买", count: 0},
        {name: "国内派送中", count: 0},
        {name: "已签收", count: 0},
        {name: "上架中", count: 0}
      ],
      porderSumList: [
        {name: "临时保存", count: 0},
        {name: "装箱中", count: 0},
        {name: "等待付款", count: 0},
        {name: "发货中", count: 0},
        {name: "已发货", count: 0},
        {name: "全部", count: 0},
        {name: "已签收", count: 0}
      ],
      warehouseSumList: [
        {name: "普通仓库", count: 0},
        {name: "收费仓库", count: 0}
      ]
    };
  },
  computed: {
    exchangeRate() {
      let exchangeRate = localStorage.getItem('exchangeRate');
      return Number(exchangeRate)
    },
  },
  created() {
    this.ordersSum();
    this.logisticsSum();
    this.porderSum();
    this.warehouseSum();
    this.$api.getHotSaleCates().then((res) => {
      if (res.code != 0) return this.$message.error(res.msg)
      this.leftFrom.keywords = res.data[0].childs[0].chinese_name;
      this.leftValue = res.data[0].chinese_name;
      this.leftOption = res.data;
      this.leftChildrenOption = res.data[0].childs;
      this.getLeftHotSaleSearch();
    });
  },
  methods: {
    // 获取每个订单数量
    ordersSum() {
      let newOrdersSumList = JSON.parse(localStorage.getItem('newOrdersSumList'));
      newOrdersSumList.forEach((item) => {
        switch (item.name) {
          case '临时保存待提出':
            this.ordersSumList[1].count = item.count;
            break;
          case '报价中':
            this.ordersSumList[2].count = item.count;
            break;
          case '等待付款':
            this.ordersSumList[3].count = item.count;
            break;
          case '正在购买':
            this.ordersSumList[4].count = item.count;
            break;
          case '采购完了':
            this.ordersSumList[5].count = item.count;
            break;
          case '出货完成':
            this.ordersSumList[6].count = item.count;
            break;
          case '问题产品-待回复':
          case '待回复':
            this.ordersSumList[7].count = item.count;
            break;
          case '全部':
            this.ordersSumList[0].count = item.count;
            break;
        }
      })
    },
    bannerPageJump() {
      window.open('https://blog.rakumart.com/detail?id=74')
    },
    goodsMouseenter(index, index1) {
      this.goodsList[index][index1].hoverStatus = true;
      this.$forceUpdate();
    },
    //商品详细鼠标移出
    goodsMouseleave(index, index1) {
      this.goodsList[index][index1].hoverStatus = false;
      this.$forceUpdate();
    },
    //图片搜索
    imgSearchGoods(img) {
      this.$store.commit("getsearchImg", img);
      window.open("/CommoditySearch?type=imgSearch&imgUrl=" + img);
    },
    getLeftHotSaleSearch() {
      this.leftFullscreenLoading = true;
      this.$api.getHotSaleSearch(this.leftFrom).then((res) => {
        this.leftFullscreenLoading = false;
        if (res.code != 0) return this.$message.error(res.msg);
        res.data.result.result.forEach((item) => {
          item.hoverStatus = false;
        })
        this.goodsList = res.data.result.result;
        this.leftTotal = res.data.result.total;
        this.ProcessingShopData();
      });
    },
    leftOptionChange(e) {
      for (let i = 0; i < this.leftOption.length; i++) {
        if (e == this.leftOption[i].chinese_name) {
          this.leftChildrenOption = this.leftOption[i].childs;
          this.leftFrom.keywords = this.leftOption[i].childs[0].chinese_name;
        }
      }
    },
    //获取中国国内物流各个状态的数量
    logisticsSum() {
      let chinaLogisticsListTableNumList = JSON.parse(localStorage.getItem('chinaLogisticsListTableNumList'));
      chinaLogisticsListTableNumList.forEach((item) => {
        switch (item.name) {
          case '等待购买':
            this.chinaLogisticsListTableNum[0].count = item.count;
            break;
          case '国内派送中':
            this.chinaLogisticsListTableNum[1].count = item.count;
            break;
          case '上架中':
            this.chinaLogisticsListTableNum[3].count = item.count;
            break;
          case '已签收':
            this.chinaLogisticsListTableNum[2].count = item.count;
            break;
        }
      })
    },
    //获取配送单
    porderSum() {
      let storageDeliverNumList = JSON.parse(localStorage.getItem('storageDeliverNumList'));
      storageDeliverNumList.forEach((item) => {
        switch (item.name) {
          case '临时保存':
            this.porderSumList[0].count = item.count;
            break;
          case '装箱中':
            this.porderSumList[1].count = item.count;
            break;
          case '等待付款':
            this.porderSumList[2].count = item.count;
            break;
          case '发货中':
            this.porderSumList[3].count = item.count;
            break;
          case '已发货':
            this.porderSumList[4].count = item.count;
            break;
          case '已签收':
            this.porderSumList[6].count = item.count;
            break;
          case '全部':
            this.porderSumList[5].count = item.count;
            break;
        }
      })
    },
    // 数据处理
    ProcessingShopData() {
      //console.log(23);
      let lack = 6 - (this.goodsList.length % 6); //取得店铺商品数离6个的倍数差多少个商品的数量
      //差多少个就从最前面取多少个填充到最后
      for (let i = 0; i < lack; i++) {
        this.goodsList.push(this.goodsList[i]);
      }
      //  分成六个一组
      this.goodsList = this.$fun.changeArr(this.goodsList, 6);
      this.$forceUpdate();
    },
    shopCarouselChange(type) {
      if (type == "left") {
        // this.$refs.shopCarousel.prev();
        if (this.leftFrom.page == 1) return this.$message.error(this.$fanyi('当前是第一页'));
        this.leftFrom.page--
        this.getLeftHotSaleSearch()
      } else if (type == "right") {
        // this.$refs.shopCarousel.next();
        if (this.leftTotal < 6) return this.$message.error(this.$fanyi('没有下一页了'))
        this.leftFrom.page++
        this.getLeftHotSaleSearch()
      }
    },
    warehouseSum() {
      let warehouseSumList = JSON.parse(localStorage.getItem('warehouseSumList'));
      let userData = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("userData"))));
      warehouseSumList.forEach((item) => {
        switch (item.name) {
          case '普通仓库':
            this.warehouseSumList[0].count += item.count
            break;
          case '装箱中':
            if (userData.is_encasement_authorized != undefined && userData.is_encasement_authorized == 1) {
              this.warehouseSumList[0].count += item.count
            }
            break;
          case '收费仓库':
            this.warehouseSumList[1].count = item.count
            break;
        }
      })
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../css/mixin.scss";

.type {
  width: 46px;
  height: 18px;
  display: inline-block;
  background: #FF4000;
  border-radius: 2px;
  margin-right: 7px;
  text-align: center;
  line-height: 18px;
  font-weight: bold;
  color: #fff;
}

.typeContainer {
  display: flex;
  align-items: center;

  .activeText {
    color: #333333;
    font-weight: bold;
    font-size: 24px;
    cursor: pointer;
    //margin-right: 60px;
    display: flex;

    img {
      width: 36px;
      height: 19px;
      margin-top: -15px;
      margin-left: -5px;
    }
  }

  .text {
    color: #999999;
    font-weight: 400;
    font-size: 24px;
    cursor: pointer;
    margin-right: 60px;
  }
}

.recommendGoodsMain {
  margin-bottom: 18px;
  background: #fff;
  padding: 26px 26px 15px;
  border-radius: 10px;

  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;

    h4 {
      color: #333333;
      font-size: 24px;
      font-weight: 600;
    }

    .iconBox {
      display: flex;
      align-items: center;

      div {
        width: 30px;
        height: 30px;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          width: 9px;
          height: 14px;
        }
      }

      div:first-child {
        margin-right: 12px;
      }
    }
  }

  .recommendGoodsBox {
    display: flex;

    .recommendGoods {
      cursor: pointer;
      width: 160px;
      min-width: 160px;
      background-color: white;
      border-radius: 6px;
      margin-right: 19px;
      transition: 0.3s;
      position: relative;
      overflow: hidden;

      .topThreeIcon {
        position: absolute;
        top: 0;
        right: 0;
      }

      .imageBox {
        width: 160px;
        height: 160px;
        margin-bottom: 5px;
        text-align: center;
        background-color: white;
        overflow: hidden;
        position: relative;

        /deep/ .el-image {
          $imgSize: 160px;
          width: 160px;
          min-width: 160px;
          height: $imgSize;
          transition: 0.3s;
          text-align: center;

          i {
            font-size: $imgSize/4;
            line-height: $imgSize;
          }
        }

        .findSimilarityContainer {
          position: absolute;
          left: 0;
          bottom: 0;
          height: 32px;
          width: 160px;
          background: #E0787E;
          cursor: pointer;
          color: #fff;
          text-align: center;
          line-height: 32px;
          font-size: 12px;
        }

        .findSimilarityContainer:hover {
          background: #C40622;
        }
      }

      .goodsTitle {
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 5px;
        color: #000000;
        font-family: "Open Sans", Sans-serif;
        font-size: 14px;
      }

      .priceContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .priceBox {
          color: #B4272D;
          font-size: 14px;
        }
      }

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 0 15px rgb(223, 223, 223);
      }
    }

    .recommendGoods:first-child {
      margin-left: 0;
    }

    .recommendGoods:last-child {
      margin-right: 0;
    }
  }

  .lookMore {
    padding: 22px 34px;
    text-align: right;
    color: #1a289d;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;

    i {
      font-weight: 800;
      font-size: 14px;
    }
  }
}

.main {
  border-radius: 10px;

  /deep/ .el-icon-s-order:before {
    font-size: 46px;
    color: #B4272B;
    margin-right: 6px;
  }

  .opt {
    margin-bottom: 20px;

    .title {
      color: #2F2F2F;
      font-size: 18px;
      margin-bottom: 21px;
    }

    .pageJumpListContainer {
      display: flex;
      align-items: center;

      .pageJumpContainer {
        width: 248px;
        height: 124px;
        background: #FFFFFF;
        border: 1px solid #E3E3E3;
        border-radius: 6px;
        margin: 0 20px 20px 0;
        padding: 30px 0 23px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .icon {
          width: 64px;
          height: 64px;
          background: #DFE4FF;
          border-radius: 50%;
          margin-right: 10px;
          flex-shrink: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .temporary {
          img {
            width: 17px;
            height: 23px;
          }
        }

        .pending {
          padding-right: 3px;

          img {
            width: 21px;
            height: 21px;
          }
        }

        .RECEPCIONPENDIENTE {
          img {
            width: 20px;
            height: 20px;
          }
        }

        .WAREHOUSE {
          img {
            width: 22px;
            height: 22px;
          }
        }

        .CONTROLDECALIDAD {
          img {
            width: 21px;
            height: 21px;
          }
        }

        .FLETEPENDIENTECOTIZACION {
          img {
            width: 18px;
            height: 22px;
          }
        }

        .ENVIOPENDIENTEDEPAGO {
          img {
            width: 23px;
            height: 23px;
          }
        }

        .ESPERADERECOGIDA {
          img {
            width: 21px;
            height: 21px;
          }
        }

        .ENTRANSITOHACIAESPAÑA {
          img {
            width: 24px;
            height: 24px;
          }
        }

        .ALMACENESPAÑA {
          img {
            width: 22px;
            height: 22px;
          }
        }

        .ENVIONACIONALENCURSO {
          img {
            width: 26px;
            height: 18px;
          }
        }

        .ENTREGADO {
          img {
            width: 22px;
            height: 22px;
          }
        }

        .right {
          .number {
            color: #2F2F2F;
            font-weight: 600;
            font-size: 30px;
            margin-bottom: 11px;
          }

          .text {
            color: #2F2F2F;
            font-size: 14px;
          }
        }
      }
    }
  }

  .indexNavigationContainer {
    border-radius: 10px;
    overflow: hidden;

    .navigationMenuContainer {
      background: #D7D7D7;
      height: 56px;
      color: #333333;
      font-weight: bold;
      font-size: 18px;
      line-height: 56px;
      padding-left: 26px;
    }

    .margin-right-87 {
      margin-right: 87px;
    }

    .margin-right-56 {
      margin-right: 56px;
    }

    .margin-right-31 {
      margin-right: 31px;
    }

    .navigationMenuChildrenContainer {
      padding: 50px;
      border: 1px solid #D7D7D7;

      background: #FFFFFF;

      .navigationContainer {
        width: 220px;
        min-width: 220px;
        height: 50px;
        cursor: pointer;

        img {
          width: 50px;
          height: 50px;
          margin-right: 7px;
        }

        .numContainer {
          div:first-child {
            color: #B4272B;
            font-weight: bold;
            font-size: 24px;
          }

          div:last-child {
            color: #000000;
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
